<template>
    <v-data-table
        :headers="headers"
        :items="entities"
        class="elevation-1"
        disable-sort
    >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Рестораны</v-toolbar-title>
                <v-spacer />
                <v-btn color="primary" @click="create" v-if="user.admin">
                    <v-icon>mdi-plus</v-icon> Добавить
                </v-btn>
            </v-toolbar>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment('DD.MM.YYYY HH:mm') }}
        </template>
        <template v-slot:[`item.title`]="{ item }">
            <span class="title">{{ item.title }}</span>
        </template>
        <template v-slot:[`item.start_page`]="{ item }">
            {{ startPage(item.start_page) }}
        </template>
        <template v-slot:[`item.open`]="{ item }">
            <a :href="`/sites/${item.id}/detail`">
                <v-icon color="primary">mdi-eye</v-icon>
            </a>
        </template>
    </v-data-table>
</template>

<script>
    import store from '../../store';
    import { mapState } from 'vuex';

    export default {
        name: 'EntitiesList',
        components: {
        },
        data: () => ({
            headers: [
                { text: 'Дата', value: 'created_at' },
                { text: 'Код', value: 'code' },
                { text: 'Заголовок', value: 'title' },
                { text: 'Стартовая страница', value: 'start_page' },
                { text: '', align: 'right', value: 'open' },
            ],
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('sites', ['entities']),
        },
        async mounted() {
            await store.dispatch('sites/fetch', {});
        },
        methods: {
            startPage(start_page) {
                return [
                    { code: 'site', title: 'Сайт' },
                    { code: 'delivery', title: 'Доставка' },
                    { code: 'menu', title: 'Электронное меню' }
                ].find(item => item.code === start_page).title;
            },
            async create() {
                await this.$router.push({ name: 'sites-create' });
            },
            async open(id) {
                await this.$router.push({ name: 'sites-detail', params: { id } });
            }
        }
    }
</script>

<style scoped>
    .title {
        font-size: 16px !important;
        line-height: 18px;
        font-weight: 700;
    }
</style>